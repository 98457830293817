<template>
  <div class="page-wrapper">
    <!-- Header ctas -->
    <Header :class="'is-fixed soumission-header'" />

    <!-- Soumission Form -->
    <section class="soumission-form" v-if="!success">
      <h1 class="big-title">{{ $t('cta') }}</h1>
      <form class="optanon-category-C0004" data-v-775ca6a2="" action="" @submit="saveForm">

        <div class="input-row visually-hidden">
          <h6>
            Protection
          </h6>
          <p>
            Si vous voyez ce champ, laissez-le vide. Merci. (If you are a human, don't fill this field!)
          </p>
          <label for="nom_entreprise" class="full">
            <span>{{ $t('form_company') }}</span>
            <input type="text" name="nom_entreprise" id="nom_entreprise" v-model="form.nom_entreprise" />
          </label>
        </div>

        <div class="input-row">
          <label for="prenom" class="half">
            <span>{{ $t('form_name') }} *</span>
            <input type="text" name="prenom" id="prenom" v-model="form.prenom" required />
          </label>
          <label for="nom" class="half">
            <span>{{ $t('form_last_name') }} *</span>
            <input type="text" name="nom" id="nom" v-model="form.nom" required />
          </label>
        </div>
        <div class="input-row">
          <label for="courriel" class="half">
            <span>{{ $t('form_mail') }} *</span>
            <input type="email" name="courriel" id="courriel" v-model="form.courriel" pattern="[^@\s]+@[^@\s]+\.[^@\s]+" required />
          </label>
          <label for="telephone" class="half">
            <span>{{ $t('form_phone') }} *</span>
            <input type="text" name="telephone" id="telephone" v-model="form.telephone" required />
          </label>
        </div>
        <div class="input-row">
          <label for="ville" class="half">
            <span>{{ $t('form_city') }}</span>
            <input type="text" name="ville" id="ville" v-model="form.ville" />
          </label>
          <label for="pays" class="half">
            <span>{{ $t('form_pays') }}</span>
            <input type="text" name="pays" id="pays" v-model="form.pays" />
          </label>
        </div>
        <div class="input-row">
          <label for="entreprise" class="half">
            <span>{{ $t('form_business') }}</span>
            <input type="text" name="entreprise" id="entreprise" v-model="form.entreprise" />
          </label>
          <label for="groupe" class="half">
            <span>{{ $t('form_type') }}</span>
            <select name="groupe" id="groupe" v-model="form.groupe">
              <option value="" selected>{{ $t('form_type_0') }}</option>
              <option :value="$t('form_type_1')" selected>{{ $t('form_type_1') }}</option>
              <option :value="$t('form_type_2')" selected>{{ $t('form_type_2') }}</option>
              <option :value="$t('form_type_3')" selected>{{ $t('form_type_3') }}</option>
              <option :value="$t('form_type_4')" selected>{{ $t('form_type_4') }}</option>
              <option :value="$t('form_type_5')" selected>{{ $t('form_type_5') }}</option>
              <option :value="$t('form_type_6')" selected>{{ $t('form_type_6') }}</option>
              <option :value="$t('form_type_7')" selected>{{ $t('form_type_7') }}</option>
              <option :value="$t('form_type_8')" selected>{{ $t('form_type_8') }}</option>
              <option :value="$t('form_type_9')" selected>{{ $t('form_type_9') }}</option>
              <option :value="$t('form_type_10')" selected>{{ $t('form_type_10') }}</option>
              <option :value="$t('form_type_11')" selected>{{ $t('form_type_11') }}</option>
            </select>
          </label>
        </div>
        <div class="input-row">
          <label for="participants" class="half">
            <span>{{ $t('form_participants') }}</span>
            <input type="number" min="1" steps="1" name="participants" id="participants" v-model="form.participants" />
          </label>
          <label for="participants_under_18" class="half">
            <span>{{ $t('form_participants_under_18') }}</span>
            <input type="number" min="1" steps="1" name="participants_under_18" id="participants_under_18" v-model="form.participants_under_18" />
          </label>
        </div>
        <div class="input-row">
          <label for="arrivee" class="half">
            <span>{{ $t('form_arrival') }} *</span>
            <input type="date" name="arrivee" id="arrivee" v-model="form.arrivee" required :min="new Date().toISOString().split('T')[0]" :max="form.depart ? new Date(form.depart).toISOString().split('T')[0] : ''" @change="updateNbNights" />
          </label>
          <label for="depart" class="half">
            <span>{{ $t('form_departure') }} *</span>
            <input type="date" name="depart" id="depart" v-model="form.depart" required :min="form.arrivee ? new Date(form.arrivee).toISOString().split('T')[0] :new Date().toISOString().split('T')[0]" @change="updateNbNights" />
          </label>
        </div>
        <div class="input-row">
          <label for="nb_unites" class="half" v-if="form.nb_nights">
            <span>{{ $t('form_chambre') }}</span>
            <input type="number" min="1" steps="1" name="nb_unites" id="nb_unites" v-model="form.nb_unites" />
          </label>
          <label for="hebergement" class="half">
            <span>{{ $t('form_lodging') }}</span>
            <select name="hebergement" id="hebergement" v-model="form.hebergement">
              <option value="" selected>{{ $t('form_lodging_0') }}</option>
              <option :value="$t('form_lodging_1')">{{ $t('form_lodging_1') }}</option>
              <option :value="$t('form_lodging_2')">{{ $t('form_lodging_2') }}</option>
              <option :value="$t('form_lodging_3')">{{ $t('form_lodging_3') }}</option>
              <option :value="$t('form_lodging_4')">{{ $t('form_lodging_4') }}</option>

            </select>
          </label>
        </div>
        <div class="input-row">
          <span for="salle_reunion" class="label">
            <span>{{ $t('form_salle') }}</span>
            <div class="checkboxes">
              <label for="oui">
                <span>{{ $t('form_oui') }}</span>
                <input type="radio" name="salle_reunion" id="oui" value="oui" v-model="form.salle_reunion" :checked="form.salle_reunion === 'oui'" />
              </label>
              <label for="non">
                <span>{{ $t('form_non') }}</span>
                <input type="radio" name="salle_reunion" id="non" value="non" v-model="form.salle_reunion" :checked="form.salle_reunion === 'non'" />
              </label>
            </div>
          </span>
        </div>
        <div class="input-row">
          <label for="details_besoins" class="half">
            <span>{{ $t('form_details') }}</span>
            <textarea name="details_besoins" id="details_besoins" v-model="form.details_besoins"></textarea>
          </label>

          <span class="separator">{{ $t('ou') }}</span>

          <label for="upload" class="half file-wrap">
            <span v-if="!form.file">
              {{ $t('form_upload') }}<br />
              <small>{{ $t('form_upload_file') }}</small>
            </span>
            <span v-else>
              Fichier <br />
              <small>{{ form.file.name }}</small>
            </span>
            <input
              type="file"
              name="upload"
              id="upload"
              @change="handleFile"
              accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf,.csv,image/*"
              v-show="!isSaving"
            />
            <div class="loader-wrap" v-show="isSaving">
              <SvgLoader v-show="isSaving" />
            </div>
          </label>
        </div>
        <div class="input-row">
          <label for="details_additionnels" class="full">
            <span>{{ $t('form_more_details') }}</span>
            <textarea name="details_additionnels" id="details_additionnels" v-model="form.details_additionnels"></textarea>
          </label>
        </div>
        <div class="input-row">
          <span for="activite" class="label">
            <span>{{ $t('form_activites') }}</span>
            <div class="checkboxes col2">
              <label for="activite">
                <span>{{ $t('form_activites_1') }}</span>
                <input type="checkbox" name="activite[]" id="activite" :value="$t('form_activites_1')" :checked="form.activite.includes('oui')" v-model="form.activite" />
              </label>
              <label for="activite2">
                <span>{{ $t('form_activites_2') }}</span>
                <input type="checkbox" name="activite[]" id="activite2" :value="$t('form_activites_2')" :checked="form.activite.includes('oui')" v-model="form.activite" />
              </label>
              <label for="activite3">
                <span>{{ $t('form_activites_3') }}</span>
                <input type="checkbox" name="activite[]" id="activite3" :value="$t('form_activites_3')" :checked="form.activite.includes('oui')" v-model="form.activite" />
              </label>
              <label for="activite4">
                <span>{{ $t('form_activites_4') }}</span>
                <input type="checkbox" name="activite[]" id="activite4" :value="$t('form_activites_4')" :checked="form.activite.includes('oui')" v-model="form.activite" />
              </label>
              <label for="activite5">
                <span>{{ $t('form_activites_5') }}</span>
                <input type="checkbox" name="activite[]" id="activite5" :value="$t('form_activites_5')" :checked="form.activite.includes('oui')" v-model="form.activite" />
              </label>
              <label for="activite6">
                <span>{{ $t('form_activites_6') }}</span>
                <input type="checkbox" name="activite[]" id="activite6" :value="$t('form_activites_6')" :checked="form.activite.includes('oui')" v-model="form.activite" />
              </label>
              <label for="activite7">
                <span>{{ $t('form_activites_7') }}</span>
                <input type="checkbox" name="activite[]" id="activite7" :value="$t('form_activites_7')" :checked="form.activite.includes('oui')" v-model="form.activite" />
              </label>
            </div>
          </span>
        </div>
        <div class="btn-wrap">
          <button type="submit" class="btn" v-if="!isSaving">{{ $t('form_send') }}</button>
          <SvgLoader v-else />
        </div>
      </form>
    </section>

    <!-- Success -->
    <div class="success" v-else>
      
      <div v-if="$route.meta.lang === 'fr'">
        <h1 class="big-title">Merci, votre demande a bien été reçue.</h1>
        Bonjour !<br><br>
        Vous recevrez une proposition via le ou les partenaires hôteliers ayant la capacité de répondre à vos besoins. Pour toutes autres questions, contactez-nous par téléphone au <a href="tel:18662146940">1 866 214-6940</a> ou par courriel à Véronique Cyr au <a href="mailto:vcyr@avtremblant.com">vcyr@avtremblant.com</a>. Nos heures d'ouverture sont du lundi au vendredi de 9h à 17h. À noter que nos bureaux seront fermés lors des jours fériés.<br><br>
        En raison du grand nombre de demandes que nous recevons chaque jour, il est possible que nous ayons besoin d’un peu plus de temps qu’à l’habitude pour vous répondre. Soyez toutefois assurés que nous vous contacterons sous peu.<br><br>
        Pour le moment, nous vous partageons quelques liens qui vous aideront à continuer votre planification:<br>
        <ul>
          <li>Découvrez les nombreux <a href="https://www.tremblant.ca/planifiez/groupes-affaires/espaces-rencontre?sc_lang=fr&webSyncID=dbbe0892-6508-140b-61c7-bc8ea25e78a1&sessionGUID=48481ced-7ce1-9272-9bca-6c0a849a1042&_ga=2.150427766.1190529353.1663856645-834248292.1663856644&_gl=1*x27e95*_ga*ODM0MjQ4MjkyLjE2NjM4NTY2NDQ.*_ga_B1YVPN56M6*MTY2Mzg1NjY0NC4xLjEuMTY2Mzg1NjY0Ny41Ny4wLjA." target="_blank">espaces de rencontre</a> à votre disposition</li>
          <li>Dénichez l’<a href="https://www.tremblant.ca/planifiez/groupes-affaires/hebergement" target="_blank">hébergement parfait</a> pour votre groupe</li>
          <li>Visualisez <a href="https://avtremblant.app.box.com/s/0qq6p8t2tm96ahbdqtkop7rey711k85o" target="_blank">les plans de salles et menus banquets</a> de la destination</li>
          <li>Profitez de nombreux autres <a href="https://groups.tremblant.ca/outils-de-planification/?lang=fr" target="_blank">outils de planification</a> (activités, accessibilité, village piétonnier, etc.)</li>
        </ul>
        Merci de votre compréhension,<br><br>
        <strong>L’équipe des ventes</strong>
      </div>

      <div v-if="$route.meta.lang === 'en'">
        <h1 class="big-title">Thank you, your request has been sent.</h1>
        Bonjour !<br><br>
        You will receive a proposal from one or more hotel partners able to accommodate your request. For any other questions, contact us by phone at <a href="tel:18662146940">1 866 214-6940</a> or by email to Véronique Cyr at <a href="mailto:vcyr@avtremblant.com">vcyr@avtremblant.com</a> Our opening hours are Monday to Friday from 9 am to 5 pm. Please note that we are closed.<br><br>
        Due to the large amount of daily requests we are currently receiving, we might require a little more time than usual to get back to you.  Please be assured that we will be in touch with you as soon as possible.<br><br>
        In the meantime, the following links will be useful in the planning stages of your group experience:<br>
        <ul style="list-style: disc; padding-left: 15px; margin: 20px 0;">
          <li>Discover the many <a href="https://www.tremblant.ca/plan/groups-business/work-spaces" target="_blank">meeting spaces</a> available</li>
          <li>Find the <a href="https://www.tremblant.ca/plan/groups-business/lodging-groups" target="_blank">perfect lodging</a> for your group</li>
          <li>Browse <a href="https://avtremblant.app.box.com/s/0qq6p8t2tm96ahbdqtkop7rey711k85o" target="_blank">meeting room floor plans and banquet menus</a></li>
          <li>Take advantage of several additional <a href="https://groups.tremblant.ca/planning-tools/?lang=en" target="_blank">planning tools</a> (activities, accessibility, pedestrian village, etc.)</li>
        </ul>
        Thank you for your understanding,<br><br>
        <strong>The Sales Team</strong>
      </div>

    </div>

    <!-- Footer -->
    <Footer class="is-white" />
  </div>
</template>

<script>
import { mapState } from 'vuex'

import Swal from 'sweetalert2'

import { saveForm, uploadFile } from '@/firebase/init'
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import SvgLoader from '@/components/svgs/SvgLoader'

export default {
  name: 'Soumission',

  head() {
    return {
      title: {
        inner: this.$t('og_title_soumission'),
      },
      meta: [
        {
          name: 'description',
          content: this.$t('og_desc_soumission'),
          id: 'desc',
        },
        // Twitter OG
        { name: 'twitter:title', content: this.$t('og_title_soumission') },
        { name: 'twitter:card', content: 'summary' },
        {
          n: 'twitter:description',
          c: this.$t('og_desc_soumission'),
        },
        // Facebook / Open Graph
        { property: 'og:title', content: this.$t('og_title_soumission') },
        { property: 'og:site_name', content: this.$t('og_title_soumission') },
        {
          property: 'og:description',
          content: this.$t('og_desc_soumission'),
        },
        // Og Image
        {
          p: 'og:image',
          c: 'https://firebasestorage.googleapis.com/v0/b/tremblant-infra.appspot.com/o/hero.jpg?alt=media&token=29e07b5d-9a11-4702-89bf-3d56d06fc310',
        },
        {
          name: 'twitter:image',
          content:
            'https://firebasestorage.googleapis.com/v0/b/tremblant-infra.appspot.com/o/hero.jpg?alt=media&token=29e07b5d-9a11-4702-89bf-3d56d06fc310',
        },
      ],
    }
  },

  components: {
    Header,
    SvgLoader,
    Footer,
  },

  data() {
    return {
      form: {
        groupe: '',
        'salle_reunion': 'non',
        activite: [],
        hebergement: '',
        'nb_nights': '',
        nom_entreprise: '',
      },
      isSaving: false,
      success: false,
    }
  },

  computed: mapState('app', ['appTitle']),

  mounted() {},

  methods: {
    updateNbNights() {
      if (this.form.arrivee && this.form.depart) {
        const arrivalDate = new Date(this.form.arrivee)
        const departureDate = new Date(this.form.depart)

        // Validate dates
        if (arrivalDate > departureDate) {
          return
        }

        const nbNights = Math.round(
          (departureDate.getTime() - arrivalDate.getTime()) / 86400000
        )

        this.form.nb_nights = nbNights
      }
    },
    async saveForm(e) {
      e.preventDefault()

      if (this.isSaving) {
        console.log(1);
        return
      }

      // Honeypot
      if (this.form.nom_entreprise) {
        console.log(2);
        return
      }

      // If the emails contains '@jourrapides' we block the form
      if (this.form.courriel.includes('@jourrapides') || this.form.courriel.includes('@jourrapide')) {
        Swal.fire({
          title: 'Erreur',
          text: this.$i18n.locale === 'fr' ? 'Veuillez utiliser une adresse courriel valide.' : 'Please use a valid email address.',
          icon: 'error',
          confirmButtonText: 'OK',
          confirmButtonColor: '#ab192d',
        })
        return
      }

      this.isSaving = true

      this.form.lang = this.$i18n.locale
      this.form.subDate = new Date()

      // console.log(this.form)

      if (this.form.arrivee && this.form.depart) {
        const arrivalDate = new Date(this.form.arrivee)
        const departureDate = new Date(this.form.depart)

        // Validate dates
        if (arrivalDate > departureDate) {
          this.isSaving = false
          console.log(4);
          return
        }

        const nbNights = Math.round(
          (departureDate.getTime() - arrivalDate.getTime()) / 86400000
        )

        this.form.nb_nights = nbNights
      }

      const results = await saveForm(this.form)
      console.log(results)
      this.isSaving = false
      this.form = {
        groupe: '',
        'salle_reunion': 'non',
        activite: [],
        hebergement: '',
        'nb_nights': '',
      }
      this.success = true
    },
    async handleFile(e) {
      this.isSaving = true

      let file = e.target.files
      if (file && file.length) {
        ;[file] = file
      }

      console.log(file)

      const results = await uploadFile(file)

      console.log(results)

      if (results && results.metadata.name) {
        this.form.file = {
          name: results.metadata.name,
          path: `https://firebasestorage.googleapis.com/v0/b/tremblant-infra.appspot.com/o/forms-files%2F${results.metadata.name}?alt=media&token=608fa205-6369-49c9-a5c5-3224bd7bb504`,
        }
      }

      console.log(this.form.file)

      this.isSaving = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
</style>

<template>
  <div class="page-wrapper">
    <!-- Alert Banner -->
    <!-- <AlertBanner /> -->

    <!-- Header ctas -->
    <Header />

    <!-- Hero -->
    <section class="hero">
      <div class="carousel-wrapper">
        <div class="carousel-cell">
          <img data-flickity-lazyload-src="/img/hero-offre-party-h2425-1.jpg" alt="Un groupe d'entrepreneurs dans le rues de Tremblant" />
        </div>
        <div class="carousel-cell">
          <img data-flickity-lazyload-src="/img/hero-offre-party-h2425-2.jpg" alt="Un groupe d'entrepreneurs dans le rues de Tremblant" />
        </div>
        <div class="carousel-cell">
          <img data-flickity-lazyload-src="/img/hero-offre-party-h2425-3.jpg" alt="Un groupe d'entrepreneurs dans le rues de Tremblant" />
        </div>
      </div>
      <div class="promotion_titles_wrap">
        <h1 class="big-title" v-html="$t('promo_hero_title')"></h1>
        <h2 class="medium-title price" v-html="$t('promo_hero_deal')"></h2>
      </div>
    </section>

    <!-- Site Nav -->
    <NavBar />

    <!-- Split Promotion -->
    <section class="split-promotion site-max-width">
      <div class="left">
        <img src="@/assets/img/offre-party-2023.webp" alt="Feux d'artifices à Tremblant" />
      </div>
      <div class="right" v-if="$route.meta.lang === 'fr'">
        <h3 class="big-title">Célébrez avec nous !</h3>
        <p class="regular-text">
          Offrez à votre équipe une expérience inoubliable avec notre forfait festif à partir de 215 $ par personne, comprenant un délicieux repas gastronomique de 3 ou 4 services, ainsi qu'une nuitée dans l'un de nos hôtels participants.
        </p>
        <p class="regular-text">
          Réservez votre party de bureau dès maintenant ! Le forfait est disponible pour des groupes de 20 personnes ou plus, en occupation simple, du dimanche au jeudi, entre le 22 octobre 2024 et le 20 janvier 2025. Les réservations peuvent être effectuées du 19 septembre 2024 au 19 janvier 2025.
        </p>
        <p class="regular-text">
          Taxes, frais de service et redevances en sus.
        </p>
        <router-link :to="{ name: $route.meta.lang === 'fr' ? 'soumission' : 'soumission-en' }" class="btn cta" title="Demandez une soumission" aria-label="Lien vers la page du formulaire de soumission">{{ $t('cta') }}</router-link>
      </div>
      <div class="right" v-else-if="$route.meta.lang === 'en'">
        <h3 class="big-title">Come celebrate with us!</h3>
        <p class="regular-text">
          Offer your team an unforgettable experience with our festive package, starting at $215 per person. This includes a delicious 3- or 4-course gourmet meal and an overnight stay at one of our participating hotels.
        </p>
        <p class="regular-text">
          Book your office party now! The package is available for groups of 20 or more, with single occupancy, from Sunday to Thursday, between October 22, 2024, and January 20, 2025. Reservations can be made from September 19, 2024, to January 19, 2025.
        </p>
        <p class="regular-text">
          Taxes, service charges, and fees are additional.
        </p>
        <router-link :to="{ name: $route.meta.lang === 'fr' ? 'soumission' : 'soumission-en' }" class="btn cta" title="Demandez une soumission" aria-label="Lien vers la page du formulaire de soumission">{{ $t('cta') }}</router-link>
      </div>
    </section>
    <div class="modalites site-max-width">
      <div class="content">
        <ul v-if="$route.meta.lang === 'fr'">
          <strong>Modalités - Forfait avec souper :</strong>
          <li>Camp de Base : Repas 3 ou 4 services en salle de banquet</li>
          <li>Fairmont Tremblant : Buffet au restaurant Le Comptoir ou repas 4 services en salle de banquet *</li>
          <li>Valide du 22 octobre au 20 janvier 2024 (selon la disponibilité)</li>
          <li>Séjour une nuit ou plus, dimanche au jeudi</li>
          <li>20 personnes et plus</li>
          <li>Prix "à partir de", par personne, en occupation simple</li>
          <li>Service, taxes et redevance en sus</li>
          <p class="regular-text">*Pour les groupes séjournant au Fairmont seulement</p>
        </ul>
        <ul v-else-if="$route.meta.lang === 'en'">
          <strong>Terms and conditions - Package including night stay and dinner :</strong>
          <li>Camp de Base Tremblant: 3- or 4-course meal in a banquet hall</li>
          <li>Fairmont Tremblant: Buffet at restaurant Le Comptoir or 4-course meal in a banquet hall *</li>
          <li>Valid from October 22 to January 20, 2024 (subject to availability)</li>
          <li>One-night stay or more, from Sunday to Thursday</li>
          <li>20 people or more</li>
          <li>Price "starting from", per person, single occupancy</li>
          <li>Service, taxes and fees not included</li>
          <p class="regular-text">*For groups staying at the Fairmont</p>
        </ul>
      </div>
    </div>

    <!-- Footer -->
    <Footer />
  </div>
</template>

<script>
import Flickity from 'flickity'

import { mapState } from 'vuex'

import Header from '@/components/Header'
import NavBar from '@/components/NavBar'
import Footer from '@/components/Footer'

require('flickity-imagesloaded')

export default {
  name: 'Promotion',

  head() {
    return {
      title: {
        inner: 'Promotion | Offre pour entreprises | Événement corporative',
      },
      meta: [
        {
          name: 'description',
          content: 'Vous voulez organiser un événement corporatif, un colloque ou même un séminaire? Tremblant apportera votre compagnie au sommet!',
          id: 'desc',
        },
        // Twitter OG
        { name: 'twitter:title', content: 'Offre pour entreprises | Événement corporative' },
        { name: 'twitter:card', content: 'summary' },
        {
          n: 'twitter:description',
          c: 'Vous voulez organiser un événement corporatif, un colloque ou même un séminaire? Tremblant apportera votre compagnie au sommet!',
        },
        // Facebook / Open Graph
        { property: 'og:title', content: 'Offre pour entreprises | Événement corporative' },
        { property: 'og:site_name', content: 'Offre pour entreprises | Événement corporative' },
        {
          property: 'og:description',
          content: 'Vous voulez organiser un événement corporatif, un colloque ou même un séminaire? Tremblant apportera votre compagnie au sommet!',
        },
        // Og Image
        {
          p: 'og:image',
          c: 'https://firebasestorage.googleapis.com/v0/b/tremblant-infra.appspot.com/o/hero.jpg?alt=media&token=29e07b5d-9a11-4702-89bf-3d56d06fc310',
        },
        {
          name: 'twitter:image',
          content:
            'https://firebasestorage.googleapis.com/v0/b/tremblant-infra.appspot.com/o/hero.jpg?alt=media&token=29e07b5d-9a11-4702-89bf-3d56d06fc310',
        },
      ],
    }
  },

  components: {
    Header,
    NavBar,
    Footer,
  },

  data() {
    return {
      heroFlk: null,
    }
  },

  computed: mapState('app', ['appTitle']),

  mounted() {
    this.heroFlk = new Flickity(document.querySelector('.carousel-wrapper'), {
      // options
      cellAlign: 'center',
      wrapAround: true,
      autoPlay: 4375,
      imagesLoaded: true,
      lazyLoad: 2,
      pageDots: false,
      prevNextButtons: true,
    })
  },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>

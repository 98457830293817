<template>
  <nav v-if="$route.name.includes('home') " class="site-nav">
    <a href="/#experience" v-scroll-to:-95>
      <span v-html="$t('menu_1')"></span>
    </a>
    <a href="/#espaces-rencontres" v-scroll-to:-95>
      <span v-html="$t('menu_2')"></span>
    </a>
    <a href="/#activite-equipe" v-scroll-to:-95>
      <span v-html="$t('menu_3')"></span>
    </a>
    <a href="/#transport" v-scroll-to:-95>
      <span v-html="$t('menu_4')"></span>
    </a>
    <router-link :to="{ name: $route.meta.lang === 'fr' ? 'promotion' : 'promotion-en' }" class="is-focus"><span>Promotion</span></router-link>
  </nav>
  <nav v-else="" class="site-nav">
    <a :href="'/'+ ($route.meta.lang === 'fr' ? '' : 'en/') + '#experience'">
      <span v-html="$t('menu_1')"></span>
    </a>
    <a :href="'/'+ ($route.meta.lang === 'fr' ? '' : 'en/') + '#espaces-rencontres'">
      <span v-html="$t('menu_2')"></span>
    </a>
    <a :href="'/'+ ($route.meta.lang === 'fr' ? '' : 'en/') + '#activite-equipe'">
      <span v-html="$t('menu_3')"></span>
    </a>
    <a :href="'/'+ ($route.meta.lang === 'fr' ? '' : 'en/') + '#transport'">
      <span v-html="$t('menu_4')"></span>
    </a>
    <router-link :to="{ name: $route.meta.lang === 'fr' ? 'promotion' : 'promotion-en' }" class="is-focus"><span>Promotion</span></router-link>
  </nav>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'NavBar',

  computed: {
    ...mapState('app', ['networkOnLine', 'appTitle', 'appShortTitle']),
  },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
